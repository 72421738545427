var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"light":""}},[_c('v-toolbar-title',[_vm._v(" Gestión de Usuarios ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"hide-details":"","prepend-icon":"mdi-magnify","single-line":"","clearable":""},on:{"keyup":_vm.buscar},model:{value:(_vm.searchTxt),callback:function ($$v) {_vm.searchTxt=$$v},expression:"searchTxt"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","to":"/main/admin/users/create"}},[_vm._v("Crear Usuario")])],1),(_vm.allDocuments)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.allDocuments},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.item.nombre))]),_c('td',[_vm._v(_vm._s(props.item.email))]),_c('td',[_vm._v(_vm._s(props.item.codigo_inquilino))]),_c('td',[_vm._v(_vm._s(props.item.codigo_propietario))]),_c('td',[(props.item.is_active)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()],1),_c('td',[(props.item.is_super)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()],1),_c('td',{staticClass:"justify-center layout px-0 align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","to":{
                  name: 'main-admin-users-edit',
                  params: { id: props.item.id },
                }}},on),[_c('v-icon',[_vm._v("edit")])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true),model:{value:(_vm.alertMsg),callback:function ($$v) {_vm.alertMsg=$$v},expression:"alertMsg"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Borrar usuario ")]),_c('v-card-text',[_vm._v(" ¿Estás seguro de que quieres borrar este usuario?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey darken-1","text":""},on:{"click":function($event){_vm.alertMsg = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.borrarUsuario(props.item.id)}}},[_vm._v(" Sí ")])],1)],1)],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])])],1)])]}}],null,false,922289408)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }