























































































import { Component, Vue } from 'vue-property-decorator';
import { readAdminUsers } from '@/store/admin/getters';
import { dispatchGetUsers, dispatchDeleteUser } from '@/store/admin/actions';

@Component
export default class AdminUsers extends Vue {
  public alertMsg = false;
  public searchTxt = '';
  public allDocuments = [] as any;

  public headers = [
    {
      text: 'Nombre',
      sortable: true,
      value: 'nombre',
      align: 'left',
    },
    {
      text: 'Email',
      sortable: true,
      value: 'email',
      align: 'left',
    },
    {
      text: 'C.Inquilino',
      sortable: true,
      value: 'codigo_inquilino',
      align: 'left',
    },
    {
      text: 'C.Prop.',
      sortable: true,
      value: 'codigo_propietario',
      align: 'left',
    },
    {
      text: 'Activo',
      sortable: true,
      value: 'is_active',
      align: 'left',
    },

    {
      text: 'Es administrador',
      sortable: true,
      value: 'is_super',
      align: 'left',
    },
    {
      text: 'Acciones',
      value: 'id',
    },
  ];
  get users() {
    return readAdminUsers(this.$store);
  }

  public async mounted() {
    await dispatchGetUsers(this.$store);
    this.allDocuments = this.users;
  }

  public async borrarUsuario(id) {
    await dispatchDeleteUser(this.$store, id);
    this.alertMsg = false;
    await dispatchGetUsers(this.$store);
  }
  public buscar() {
    if (this.searchTxt.length > 0) {
      let alldocs = [] as any;
      alldocs = this.users;
      const filtro = alldocs.filter((doc) => {
        return (
          this.stripVowelAccent(doc.nombre.toLowerCase()).indexOf(
            this.stripVowelAccent(this.searchTxt.toLowerCase()),
          ) !== -1 ||
          this.stripVowelAccent(doc.email.toLowerCase()).indexOf(
            this.stripVowelAccent(this.searchTxt.toLowerCase()),
          ) !== -1 ||
          this.stripVowelAccent(doc.codigo_propietario?.toLowerCase()).indexOf(
            this.stripVowelAccent(this.searchTxt.toLowerCase()),
          ) !== -1 ||
          this.stripVowelAccent(doc.codigo_inquilino?.toLowerCase()).indexOf(
            this.stripVowelAccent(this.searchTxt.toLowerCase()),
          ) !== -1
        );
      });
      this.allDocuments = filtro;
    } else {
      this.allDocuments = this.users;
    }
  }
  public stripVowelAccent(str) {
    const rExps = [
      { re: /[\xC0-\xC6]/g, ch: 'A' },
      { re: /[\xE0-\xE6]/g, ch: 'a' },
      { re: /[\xC8-\xCB]/g, ch: 'E' },
      { re: /[\xE8-\xEB]/g, ch: 'e' },
      { re: /[\xCC-\xCF]/g, ch: 'I' },
      { re: /[\xEC-\xEF]/g, ch: 'i' },
      { re: /[\xD2-\xD6]/g, ch: 'O' },
      { re: /[\xF2-\xF6]/g, ch: 'o' },
      { re: /[\xD9-\xDC]/g, ch: 'U' },
      { re: /[\xF9-\xFC]/g, ch: 'u' },
      { re: /[\xD1]/g, ch: 'N' },
      { re: /[\xF1]/g, ch: 'n' },
    ];
    if (str) {
      for (let i = 0, len = rExps.length; i < len; i++) {
        str = str.replace(rExps[i].re, rExps[i].ch);
      }
    } else {
      return '';
    }
    return str;
  }
}
